<template>
  <a class="header__item" :href="item.link">{{ item.title }}</a>
</template>

<script>
export default {
  name: "TheHeaderMenuItem",
  props: {
    item: Object,
  },
};
</script>

<style lang="stylus" scoped>
.header {
  &__item {
    margin-right 24px
    font-size 13px
    line-height 18px
    color #6D6D73
    text-decoration none
    border-bottom 2px solid transparent
    transition border-bottom 0.35s, color 0.35s
    &:last-child {
      margin-right 0
    }
    &:hover {
      color #242729
      border-bottom 2px solid #DD0A34
    }
  }
}
</style>
