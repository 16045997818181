<template>
  <section id="capabilities" class="tabs-slider">
    <div class="container">
      <h2 class="tabs-slider__title" v-html="tabsSlider.title"></h2>
      <div
        class="tabs-slider__description"
        v-html="tabsSlider.description"
      ></div>
      <div class="tabs-slider__wrap">
        <swiper
          :modules="[Thumbs]"
          watch-slides-progress
          @swiper="setThumbsSwiper"
          :space-between="swiperOptions.tabsSlider.between"
          :slides-per-view="swiperOptions.tabsSlider.slides"
        >
          <swiper-slide v-for="(slide, index) in tabsSlider.tabs" :key="index">
            <div class="tabs-slider__item">
              <img
                class="tabs-slider__item-img"
                :src="slide.img"
                :alt="slide.text"
              />
              <div class="tabs-slider__item-title" v-html="slide.text"></div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <swiper
        :modules="[Pagination, Thumbs]"
        :pagination="pagination"
        :thumbs="{ swiper: thumbsSwiper }"
      >
        <swiper-slide v-for="(item, index) in tabsSlider.content" :key="index">
          <div class="tabs-slider__content">
            <div class="tabs-slider__content-wrap">
              <div class="tabs-slider__content-title" v-html="item.title"></div>
              <div class="tabs-slider__content-text" v-html="item.text"></div>
            </div>
            <img
              class="tabs-slider__content-img"
              :src="item.img"
              :alt="item.text"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import swiperOptions from "../swiperOptions.json";
import "swiper/css";
import "swiper/css/pagination";
export default {
  name: "TabsSlider",
  props: {
    tabsSlider: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      Pagination,
      thumbsSwiper,
      setThumbsSwiper,
      swiperOptions,
      pagination: {
        clickable: true,
        renderBullet() {
          return "<span class='swiper-pagination-bullet tabs-slider__bullet'></span>";
        },
      },
    };
  },
};
</script>

<style lang="stylus">
.tabs-slider {
  margin-bottom 88px
  padding-top 56px

  @media (max-width: 1023px) {
    margin-bottom 48px
    padding-top 32px
  }

  &__title {
    margin-bottom 12px
    font-size 28px
    line-height 36px

    @media (max-width: 1023px) {
      margin-bottom 8px
      font-size 20px
      line-height 26px
    }
  }

  &__description {
    margin-bottom 32px
    font-size 17px
    line-height 24px

    @media (max-width: 1023px) {
      font-size 15px
      line-height 22px
    }
  }

  &__wrap {
    margin-bottom 50px

    .swiper-slide-thumb-active {
      .tabs-slider__item {
        background-color #f7f7f7
        border-color #f7f7f7
      }
    }

    @media (max-width: 1023px) {
      display none
    }
  }

  &__item {
    width 256px
    padding 23px 24px 23px 23px
    border-radius 8px
    box-sizing border-box
    border 1px solid #e4e8ed
    transition background-color 0.3s, border-color 0.3s

    &:hover {
      cursor pointer
      background-color #f7f7f7
      border-color #f7f7f7
    }
  }

  &__item-img {
    margin-bottom 13px
  }

  &__item-title {
    max-width 160px
    font-size 15px
    line-height 22px
  }

  &__content {
    display flex
    justify-content space-between
    align-items center
    margin-top -72px

    @media (max-width: 1023px) {
      margin-top -40px
      flex-direction column-reverse
      align-items flex-start
    }
  }

  &__content-wrap {
    max-width 496px
    margin-top -25px
  }

  &__content-title {
    margin-bottom 16px
    font-size 20px
    line-height 26px
    font-weight 700

    @media (max-width: 1023px) {
      margin-bottom 8px
      font-size 17px
      line-height 24px
    }
  }

  &__content-text {
    font-size 17px
    line-height 24px

    @media (max-width: 1023px) {
      font-size 13px
      line-height 18px
    }
  }

  &__content-img {
    max-width 544px

    @media (max-width: 1023px) {
      display block
      margin 0 auto 48px
      max-width 256px
    }
  }

  .swiper-wrapper {
    padding-bottom 30px
  }

  .swiper-pagination {
    bottom -3px
  }

  &__bullet {
    width 6px
    height 6px
  }

  .swiper-pagination-bullet-active {
    background-color #dd0a34
  }
}
</style>
