import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/pages/MainPage.vue";
import RepairAccessPage from "@/pages/RepairAccessPage.vue";

const routes = [
  {
    path: "/",
    component: MainPage,
  },
  {
    path: "/recovery",
    component: RepairAccessPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
