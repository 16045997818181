<template>
  <nav class="header__menu">
    <the-header-menu-item
      v-for="(item, index) in menu"
      :item="item"
      :key="index"
    />
  </nav>
</template>

<script>
import TheHeaderMenuItem from "./TheHeaderMenuItem.vue";
export default {
  name: "TheHeaderMenu",
  components: {
    TheHeaderMenuItem,
  },
  props: {
    menu: Array,
  },
};
</script>

<style lang="stylus" scoped>
.header {
  &__menu {
    display flex
    align-items center

    @media (max-width: 1023px) {
      display none
    }
  }
}
</style>
