<template>
  <the-header :header="data.header" />
  <router-view></router-view>
  <base-footer :footer="data.footer" />
</template>

<script>
import data from "./data.json";
import TheHeader from "./components/TheHeader/TheHeader";
import BaseFooter from "./components/BaseFooter";
export default {
  name: "App",
  setup() {
    return {
      data,
    };
  },
  components: {
    TheHeader,
    BaseFooter,
  },
};
</script>

<style lang="stylus" src="./assets/css/layout.styl"></style>
