<template>
  <div class="documents__item">
    <img class="documents__item-img" :src="icon" :alt="item.name" />
    <div class="documents__item-wrap">
      <a class="documents__item-link" :href="item.src">{{ item.name }}</a>
      <div class="documents__item-size">{{ item.size }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDocumentsItem",
  props: {
    item: Object,
    icon: String,
  },
};
</script>

<style lang="stylus" scoped>
.documents {
  &__item {
    display flex
    margin-bottom 20px
    &:last-child {
      margin-bottom 0
    }
  }

  &__item-img {
    width 32px
    height 32px
    margin-right 16px

    @media (max-width: 1023px) {
      margin-right 13px
    }
  }

  &__item-wrap {
    padding-left 4px
  }

  &__item-link {
    font-size 15px
    line-height 22px
  }

  &__item-size {
    margin-top 8px
    font-size 13px
    line-height 18px
    color #919399
  }
}
</style>
