<template>
  <main>
    <main-banner :mainBanner="data.mainBannerRepair" />
    <repair-step :repairAccsess="data.repairAccsess.steps" />
  </main>
</template>

<script>
import data from "@/data.json";
import MainBanner from "@/components/MainBanner";
import RepairStep from "@/components/RepairStep";
export default {
  name: "RepairAccessPage",
  setup() {
    return {
      data,
    };
  },
  components: {
    MainBanner,
    RepairStep,
  },
};
</script>
