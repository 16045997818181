<template>
  <div class="repair-step">
    <div class="container">
      <div
        class="repair-step__item"
        v-for="(item, index) in repairAccsess"
        :key="index"
      >
        <div class="repair-step__content">
          <h3 class="repair-step__title" v-html="item.title"></h3>
          <p
            v-if="$isMobile() && item.textOnMobile"
            class="repair-step__text"
            v-html="item.textOnMobile"
          ></p>
          <p v-else class="repair-step__text" v-html="item.text"></p>
          <a
            v-if="item.buttonHref"
            class="repair-step__button"
            :href="item.buttonHref"
            target="_blank"
            v-html="item.buttonText"
          >
          </a>
        </div>
        <div class="repair-step__picture">
          <img class="repair-step__img" :src="item.image" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RepairStep",
  props: {
    repairAccsess: Array,
  },
};
</script>

<style lang="stylus" scoped>
.repair-step {
  padding-top 56px

  @media (max-width 1023px) {
    padding-top 48px
  }

  &__item {
    display flex
    justify-content space-between
    align-items center
    margin-bottom 40px
    &:first-child {
      & ^[0]__img {
        max-width 298px
      }
    }
    &:last-child {
      margin-bottom 80px
    }
    @media (max-width 1023px) {
      flex-direction column
      align-items flex-start
      margin-bottom 48px
      &:first-child {
        & ^[0]__picture {
          display none
        }
      }
      &:last-child {
      margin-bottom 48px
    }
    }
  }

  &__content {
    max-width 544px
    @media (max-width 1023px) {
      max-width 100%
    }
  }

  &__title {
    margin-bottom 12px
    font-size 28px
    line-height 36px
    @media (max-width 1023px) {
      margin-bottom 8px
      font-size 20px
      line-height 26px
    }
  }

  &__text {
    font-size 17px
    line-height 24px
    @media (max-width 1023px) {
      font-size 15px
      line-height 22px
    }
  }

  &__button {
    display none
    @media (max-width 1023px) {
      display inline-flex
      align-items center
      height 40px
      margin-top 24px
      padding 0 16px
      font-size 15px
      line-height 22px
      border-radius 8px
      color #ffffff
      background-color #dd0a34
    }
  }

  &__picture {
    width 544px
    text-align center
    @media (max-width 1023px) {
      width 100%
    }
  }

  &__img {
    max-width 544px
    @media (max-width 1023px) {
      max-width 276px
    }
  }
}
</style>
