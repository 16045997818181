<template>
  <section class="main-banner">
    <div class="container">
      <div class="main-banner__wrap">
        <div class="main-banner__content">
          <h1 class="main-banner__title" v-html="mainBanner.title"></h1>
          <div
            class="main-banner__description"
            v-html="mainBanner.description"
          ></div>
          <div class="main-banner__qr">
            <img
              class="main-banner__qr-img"
              v-if="mainBanner.qrImg"
              :src="mainBanner.qrImg"
              alt="QR код"
            />
            <div class="main-banner__qr-text">
              <div
                class="main-banner__qr-text-item"
                v-if="mainBanner.text"
                v-html="mainBanner.text"
              ></div>
              <a
                class="main-banner__qr-link"
                href="https://apps.apple.com/ru/app/id1641088465"
                target="_blank"
              >
                <img
                  class="main-banner__qr-text-item-icon"
                  v-if="mainBanner.appleImg"
                  :src="mainBanner.appleImg"
                  alt="Apple Store"
              /></a>
            </div>
          </div>
          <a
            v-if="mainBanner.buttonLink"
            :href="mainBanner.buttonLink"
            class="main-banner__btn"
            v-html="mainBanner.buttonText"
            target="_blank"
          ></a>
        </div>
        <img class="main-banner__img" :src="mainBanner.mainImg" alt="#" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainBanner",
  props: ["mainBanner"],
};
</script>

<style lang="stylus" scoped>
.main-banner {
  padding-top 62px
  background #f7f7f7

  @media (max-width: 1023px) {
    padding-top 22px
  }

  &__wrap {
    display flex
    justify-content space-between
    align-items center

    @media (max-width: 1023px) {
      flex-direction column
      align-items flex-start
      padding-left 9px
    }
  }

  &__content {
    max-width 544px

    @media (max-width: 1023px) {
      margin-bottom 46px
      padding-top 0
    }
  }

  &__title {
    margin-bottom 12px
    font-size 36px
    line-height 44px
    font-weight 700

    @media (max-width: 1023px) {
      margin-bottom 8px
      font-size 20px
      line-height 26px
    }
  }

  &__description {
    margin-bottom 25px
    font-size 17px
    line-height 24px

    @media (max-width: 1023px) {
      margin-bottom 16px
      font-size 15px
      line-height 22px
    }
  }

  &__qr {
    display flex
    justify-content space-between
    max-width 328px

    @media (max-width: 1023px) {
      display none
    }
  }

  &__qr-img {
    max-width 104px
  }

  &__qr-text {
    max-width 208px
  }

  &__qr-text-item {
    margin-bottom 32px
    font-size 11px
    line-height 16px
    color #919399
  }

  &__qr-text-item-icon {
    width 40px
    height 40px
    box-shadow 0 0 16px rgba(79, 105, 118, 0.1)
  }

  &__btn {
    display none

    @media (max-width: 1023px) {
      display inline-block
      padding 10px 17px 8px 16px
      font-size 15px
      line-height 22px
      color #ffffff
      background-color #dd0a34
      border-radius 8px

      &:hover {
        background-color #c31639
      }
    }
  }

  &__img {
    max-width 544px

    @media (max-width: 1023px) {
      display block
      margin 0 auto
      max-width 300px
    }
  }
}
</style>
