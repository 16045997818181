<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__wrap">
        <img class="footer__logo" :src="footer.logo" alt="К деньгам" />
        <div class="footer__copyright">
          {{ currentYear }} ООО &laquo;Инно Консалтинг&raquo;
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BaseFooter",
  props: {
    footer: Object,
  },
  setup() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="stylus" scoped>
.footer {
  padding 40px 0 48px
  background-color #0c0c0c

  @media (max-width: 1023px) {
    padding 40px 0
  }

  &__wrap {
    display flex
    justify-content space-between
    align-items center

    @media (max-width: 1023px) {
      flex-direction column
      align-items flex-start
    }
  }

  &__logo {
    max-width 162px
  }

  &__copyright {
    font-size 13px
    line-height 18px
    color #919399

    @media (max-width: 1023px) {
      width 100%
      margin-top 24px
      padding-top 25px
      font-size 11px
      line-height 16px
      border-top 1px solid #343434
    }
  }
}
</style>
