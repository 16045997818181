<template>
  <main>
    <main-banner :mainBanner="data.mainBanner" />
    <tabs-slider :tabsSlider="data.tabsSlider" />
    <instruction-main :instruction="data.instruction" />
    <base-documents :documents="data.documents" />
  </main>
</template>

<script>
import data from "@/data.json";
import MainBanner from "@/components/MainBanner";
import TabsSlider from "@/components/TabsSlider";
import InstructionMain from "@/components/InstructionMain";
import BaseDocuments from "@/components/BaseDocuments/BaseDocuments";
export default {
  name: "MainPage",
  setup() {
    return {
      data,
    };
  },
  components: {
    MainBanner,
    TabsSlider,
    InstructionMain,
    BaseDocuments,
  },
};
</script>
