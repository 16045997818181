<template>
  <section id="instruction" class="instruction">
    <div class="container">
      <div class="instruction__wrap">
        <div class="instruction__header">
          <h2 class="instruction__title">{{ instruction.title }}</h2>
          <div class="instruction__description">
            <p
              class="instruction__text"
              v-for="(item, index) in instruction.description"
              :key="index"
              v-html="item"
            ></p>
          </div>
          <router-link class="instruction__link" to="/recovery"
            >Как восстановить доступ</router-link
          >
        </div>
      </div>
      <div class="instruction__content">
        <instruction-item :instruction="instruction" />
      </div>
      <div class="instruction__note" v-html="instruction.note"></div>
    </div>
  </section>
</template>

<script>
import InstructionItem from "@/components/InstructionItem";

export default {
  name: "InstructionMain",
  props: {
    instruction: Object,
  },
  components: {
    InstructionItem,
  },
};
</script>

<style lang="stylus" scoped>
.instruction {
  margin-bottom 80px

  @media (max-width: 1023px) {
    margin-bottom 47px
  }

  &__wrap {
    display flex
    justify-content space-between
    align-items flex-start
    margin-bottom 32px

    @media (max-width: 1023px) {
      margin-bottom 24px
      flex-direction column
    }
  }

  &__header {
    max-width 100%
  }

  &__title {
    margin-bottom 12px
    font-size 28px
    line-height 36px

    @media (max-width: 1023px) {
      margin-bottom 8px
      font-size 20px
      line-height 26px
    }
  }

  &__description {
    margin-bottom 12px
    font-size 17px
    line-height 24px

    @media (max-width: 1023px) {
      margin-bottom 8px
      font-size 15px
      line-height 22px
    }
  }

  &__text {
    margin-bottom 12px
  }

  &__content {
    position relative
    display flex
    justify-content space-between
    @media (max-width: 1023px) {
      overflow-x auto
      flex-wrap nowrap
    }
  }

  &__link {
    font-size 17px
    line-height 24px
    color #DD0A34
    @media (max-width: 1023px) {
      font-size 15px
      line-height 22px
    }
  }

  &__note {
    margin-top 32px
    font-size 13px
    line-height 18px
    color #919399
    @media (max-width: 1023px) {
      margin-top 25px
      font-size 11px
      line-height 16px
    }
  }
}
</style>
