<template>
  <header class="header">
    <div class="container">
      <div class="header__wrap">
        <router-link to="/">
          <img class="header__logo" :src="header.logo" alt="К деньгам" />
        </router-link>
        <the-header-menu :menu="header.menu" v-if="$route.path === '/'" />
      </div>
    </div>
  </header>
</template>

<script>
import TheHeaderMenu from "./TheHeaderMenu.vue";
export default {
  name: "TheHeader",
  components: {
    TheHeaderMenu,
  },
  props: {
    header: Object,
  },
};
</script>

<style lang="stylus" scoped>
.header {
  padding 19px 0
  background #f7f7f7

  &__wrap {
    display flex
    align-items center
  }

  &__logo {
    max-width 164px
    margin-right 48px

    @media (max-width: 1023px) {
      max-width 146px
      margin-right 0
    }
  }

  @media (max-width: 1023px) {
    padding 11px 0
  }
}
</style>
