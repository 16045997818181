<template>
  <div
    class="instruction__item"
    v-for="(item, index) in instruction.content.slides"
    :key="index"
  >
    <img class="instruction__item-img" :src="item.img" :alt="item.text" />
    <div class="instruction__item-wrap">
      <div class="instruction__item-number">{{ index + 1 }}</div>
      <div class="instruction__item-text" v-html="item.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionItem",
  props: {
    instruction: Object,
  },
};
</script>

<style lang="stylus" scoped>
.instruction {

  &__item {
    max-width 255px
    @media (max-width: 1023px) {
      min-width 240px
      margin-right 32px
      &:last-child {
        margin-right 0
      }
    }
  }

  &__item-img {
    margin-bottom 20px
    border-radius 8px
    background-color #f7f7f7

    @media (max-width: 1023px) {
      margin-bottom 10px
    }
  }

  &__item-wrap {
    display flex
    justify-content space-between
    align-items flex-start
    padding-right 15px

    @media (max-width: 1023px) {
      justify-content flex-start
    }
  }

  &__item-number {
    margin-right 17px
    font-size 48px
    line-height 52px
    font-weight 300
    color #dd0a34

    @media (max-width: 1023px) {
      margin-right 15px
      font-size 24px
      line-height 26px
      font-weight 400
    }
  }

  &__item-text {
    font-size 15px
    line-height 22px
  }
}
</style>
