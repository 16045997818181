<template>
  <section id="documents" class="documents">
    <div class="container">
      <h2 class="documents__title">{{ documents.title }}</h2>
      <div class="documents__wrap">
        <base-documents-item
          v-for="(item, index) in documents.list"
          :item="item"
          :icon="documents.icon"
          :key="index"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BaseDocumentsItem from "./BaseDocumentsItem.vue";

export default {
  name: "BaseDocuments",
  components: {
    BaseDocumentsItem,
  },
  props: {
    documents: Object,
  },
};
</script>

<style lang="stylus" scoped>
.documents {
  margin-bottom 80px

  @media (max-width: 1023px) {
    margin-bottom 70px
  }

  &__title {
    margin-bottom 32px
    padding-left 4px
    font-size 28px
    line-height 36px

    @media (max-width: 1023px) {
      margin-bottom 24px
      padding-left 1px
      font-size 20px
      line-height 26px
    }
  }
}
</style>
